import React,{useState} from "react";
import QRCode from "./../../components/QRgenerator";
//preklady
import { withNamespaces } from 'react-i18next';

const Generate=({t})=>{
  const [genText,setGenText]=useState('https://qrcode.rezz.eu/');
  function setValue(e) {
    const val = e.target.value;
    //console.log(val);
    setGenText(val);
  }

  const downloadQR = (props) => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return(
    <div style={{border:"none"}}>
      <h2 style={{fontWeight:"bold","fontSize":"28px"}}>{t('genMsg1')}</h2>
      <br />
      <input className="genInput" maxLength="4296" type="text" value={genText} onChange={(e)=>setValue(e)}></input><button className="del" onClick={()=>setGenText('')}>X</button>
      <div style={{clear:"both","marginTop":"10px"}}>
        <QRCode val={genText}/>
      </div>        

      <button className="okbtn" onClick={downloadQR}> {t('genMsg2')} </button>
    </div>
  )
}

export default withNamespaces()(Generate);