import React, { useEffect, useState } from "react";
import app from './../asets/app.png';


const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return <div style={{left:"50px",position:"absolute",cursor:"pointer"}} onClick={()=>{alert('Application is starting.\nPlease wait few more seconds.')}}><img src={app} height="38"/></div>;
  }
  return (
    <div style={{left:"50px",position:"absolute",cursor:"pointer"}}><img src={app} onClick={onClick} height="38" title="Install as application"/></div>
  );
};

export default InstallPWA;
