import React, { useState, useEffect } from 'react';

//preklady
import { withNamespaces } from 'react-i18next';

const Archive=({t})=>{
  const [items, setItems] = useState([]);
  // Load from localStorage when the component mounts
  useEffect(() => {
    const savedItems = JSON.parse(localStorage.getItem('items'));
    if (savedItems) {
      setItems(savedItems.reverse());
    }
  }, []);

  //vycistenie local storagu
  const handleClearLocalStorage = () => {
    localStorage.removeItem('items');
    setItems([]); // Clear the items state
  };

  // Helper function to check if the item is a URL
  const isValidUrl = (str) => {
    try {
      new URL(str); // Tries to construct a URL object
      return true;
    } catch (_) {
      return false;
    }
  };

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {      
      return `<a href="${url}" target=_blank>${url}</a>`;
    })
  }  

  return(
    <div style={{border:"none","width":"80%",margin:"auto"}}>
      <center><div><h1>{t('archive1')}</h1></div></center>
      <br />
      <ul>
        {items.map((item, index) => (
          <li key={index} className="archiveItem">
            {/* Check if the item is a valid URL */}
            {isValidUrl(item) ? (
              <a href={item} target="_blank" rel="noopener noreferrer">
                {item}
              </a>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: urlify(item) }} />
              // dangerouslySetInnerHTML={urlify(item)}
            )}
          </li>
        ))}
      </ul>
      <br />
      <center><button class="okbtn" style={{width:'200px'}} onClick={()=>{handleClearLocalStorage()}}>{t('archive2')}</button></center>

    </div>
  )
}

export default withNamespaces()(Archive);