import React, {useState, useEffect} from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import QrReader from 'react-qr-reader';
//preklady
import { withNamespaces } from 'react-i18next';
import Slider from 'react-input-slider';
//bootstrap
import { Modal, Alert, Card, Button } from "react-bootstrap";
//jquery
import $ from "jquery";
let incrVal=0;
let displayFlag=0;


const Reader=({t})=>{
  const [state, setState] = useState({ x: 350 });
  //informacny modal
  const [modalObj,setModalObj] = useState({"show":false,"variant":"alert alert-success","msg":["msg"],"refresh":false});
  //pre archiv precitanych kodov nasledovne 2 usestates
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);  

  // useEffect(()=>{
  //   console.log(items);
  // },[items])


  useEffect(()=>{
    let vH = window.innerHeight;
    let vW = window.innerWidth;
    displayFlag=0;
    //precitame co je v localStorage
    const savedItems = JSON.parse(localStorage.getItem('items'));
    if (savedItems) {
      setItems(savedItems);
    }    
  },[])

  //const base45 = require('base45');
//***********  FUNKCIE */
  function increator() {
    incrVal++;
    return incrVal;
  }

  //zabezpeci zobrazenie textu do divu ktory bude na striedacku inak podfarbeny
  function returnText(text) {
    if (incrVal%2) {
      return (<div style={{padding:"5px","background":"#f0f0f0"}}>{text}</div>);
    } else {
      return (<div style={{padding:"5px","background":"#e0e0e0"}}>{text}</div>);
    }
  }
  const handleError = err => {
    console.log(err.name);
    let errorMsg=t('camerr1');
    if (err.name==='NotAllowedError') {
      errorMsg=t('camerr2');
    } else if (err.name==='NotFoundError') {
      errorMsg=t('camerr3');
    } else if (err.name==='NotSupportedError') {
      errorMsg=t('camerr4');
    } else if (err.name==='NotReadableError') {
      errorMsg=t('camerr5');
    } else if (err.name==='OverconstrainedError') {
      errorMsg=t('camerr6');
    }
    setModalObj({"show":true,"variant":"alert","msg":[errorMsg],"refresh":false});     
  }

  const handleScan = data => {
    if (displayFlag) return; //ak sa zobrazuje vysledok skanu, neskanuj dalsie
    if (data) {
      //console.log('data: '+data);
      //console.log(data.substr(0,4));
      if (data.substr(0,4)==='HC1:') {
        //covid pas
      // } else if (validateUrl(data)) {
      //   //url stranka
         setModalObj({"show":true,"variant":"alert","msg":[t("confirm1")],"refresh":false}); 
      // 
      } else {
        //vlozime do archivu
        handleAddItem(data);
        //text
        showText(data);
      }
    }
  }

  //ulozenie do localStorage
  const handleAddItem = (value) => {
    const updatedItems = [...items, value]; // Add new item to the array
    if (updatedItems.length > 20) {
      updatedItems.shift(); // Remove the oldest item if array length exceeds 10
    }
    saveItems(updatedItems); // Save updated array to localStorage
    setInputValue(''); // Clear input after adding
  };
  // Save to localStorage when the array of items changes
  const saveItems = (newItems) => {
    localStorage.setItem('items', JSON.stringify(newItems));
    setItems(newItems);
  };


  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {      
      return `<a href="${url}" target=_blank>${url}</a>`;
    })
  }

  function showText(txt) {
    $("#citacka").hide();
    $("#qrObsah").html('');$("#qrObsah").append(urlify(txt)); //z urliek vyrobim linky
    $("#obsah").show();
    displayFlag=1;
  }
//*********** END FUNKCIE */


  return(
    <>
    {/* <button onClick={()=>{console.log(validateUrl('asas https://kua.sk asas'))}}>zzzzzzzzz</button> */}
    {/* <button onClick={()=>{console.log(urlify('asas https://kua.sk asas'))}}>zzzzzzzzz</button> */}
      <div className="centerMe" style={{border:"1px dashed gray","width":`${state.x}px`,marginTop:"10px"}} id="citacka">
      {/* <input type="text" id="zzz"></input>
      <button onClick={()=>{handleAddItem(document.getElementById('zzz').value)}}>add</button> */}
      <Slider
        axis="x"
        x={state.x}
        xmax={600}
        xmin={250}
        onChange={({ x }) => setState(state => ({ ...state, x }))}

        styles={{
          track: {
            backgroundColor: '#808080',
            border:"1px solid black"
          },
          active: {
            backgroundColor: '#e0e0e0',
            border:"1px solid black"
          },
          thumb: {
            width: 35,
            height: 35,
            opacity: 0.5,
            background: "black"
          },
          disabled: {
            opacity: 0.5
          }
        }}
      />    
      <br /><br />


        <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
        />
      </div>

      <div id="obsah" style={{display:"none"}}>
        <center><h1>{t('readerContent')}</h1></center>
        <br />
        <div className="qrObsah" id="qrObsah"></div>
        <br />
        <center><button className="okbtn" onClick={()=>{displayFlag=0;$("#citacka").show();$("#obsah").hide();}}>OK</button></center>

      </div>

      {/* Oznamovaci modal */}
      <Modal show={modalObj.show} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Oznámenie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={modalObj.variant} style={{maxHeight:"50vh",overflow:"auto"}}>
            {
              modalObj.msg.map((item) => {
                return (<div key={increator()}>{returnText(item)}<br /></div>)
              })
            }
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={
            ()=>{
              setModalObj({"show":false,"variant":"alert","msg":["",""],"refresh":false});  //zavretie dialogu
            }
            }>
            {t("confirmY")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default withNamespaces()(Reader);