import { exists } from "i18next";
import { set } from "lodash";
import React,{useState,useEffect} from "react";
import Slider from 'react-input-slider';
var QRCode = require('qrcode.react');


const QRgenerator=(props)=>{
const [val, setVal]=useState(props.val);
const [state, setState] = useState({ x: 250 });

useEffect(()=>{
  if (props.val==='undefined') {
    return;
  }
  setVal(props.val)
},[props.val])

  return(
    <>
      <Slider
        axis="x"
        x={state.x}
        xmax={500}
        xmin={100}
        onChange={({ x }) => setState(state => ({ ...state, x }))}

        styles={{
          track: {
            backgroundColor: '#808080',
            border:"1px solid black"
          },
          active: {
            backgroundColor: '#e0e0e0',
            border:"1px solid black"
          },
          thumb: {
            width: 35,
            height: 35,
            opacity: 0.5,
            background: "black"
          },
          disabled: {
            opacity: 0.5
          }
        }}
      />    
      <br /><br />
      
      <QRCode id="123456" value={val} size={state.x} />
      

    </>
  )
}

export default QRgenerator;