class flashlightHandler {

  static track; //the video track which is used to turn on/off the flashlight
  static isEnabled=false;

  static accessFlashlight(errMsg) {
      //Test browser support
      if (!('mediaDevices' in window.navigator)) {
          //alert("Media Devices not available.");
          alert(errMsg);
          this.isEnabled=false;
          return;
      };

      //Get the environment camera (usually the second one)
      window.navigator.mediaDevices.enumerateDevices().then((devices) => {

          const cameras = devices.filter((device) => device.kind === 'videoinput');
          if (cameras.length === 0) {
              //alert("No camera found. If your device has camera available, check permissions.");
              alert(errMsg);              
              this.isEnabled=false;
              return;
          };
          
          const camera = cameras[cameras.length - 1];
          
          window.navigator.mediaDevices.getUserMedia({
              video: {
                  deviceId: camera.deviceId
              }
          }).then((stream) => {
              this.track = stream.getVideoTracks()[0];
              
              if (!(this.track.getCapabilities().torch)) {
                  //alert("No torch available.");
                  alert(errMsg); 
                  this.isEnabled=false;
              } else {
                this.isEnabled=true;
              }; 
          });
      });
  }

  static setFlashlightStatus(status) {
      if (!this.isEnabled) return;
      this.track.applyConstraints({
          advanced: [{
              torch: status
          }]
      });
  }
}

export default flashlightHandler;